<template>
    <div class="pushList-wrapper">

        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item>公众号/小程序</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/officialAccountsOrApplet' }">授权公众号</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/publicAccountManagement', query: { appid: appid, nickName: nickName, headImg: headImg } }">公众号管理</el-breadcrumb-item>
                <el-breadcrumb-item>模板消息推送</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title-item">
                <div class="title-left">
                    <div class="title-t">消息推送列表</div>
                </div>

                <div class="title-right">
                    <div class="rule-item">
                        <el-input type="text" v-model="messName" placeholder="搜索推送名称"
                         clearable @change="templatSettingList">
                            <template #append>
                                <el-button slot="append" icon="el-icon-search" @click="templatSettingList"></el-button>
                            </template>
                        </el-input>
                    </div>

                    <div class="btns newReply-btn" @click="linkNewMess">新增推送</div>
                </div>
            </div>
            
            <div class="tab-pane">
                <el-tabs v-model="sendType" @tab-click="handleClick($event)">
                    <el-tab-pane label="即时推送" name="1"></el-tab-pane>
                    <el-tab-pane label="定时推送" name="2"></el-tab-pane>
                    <el-tab-pane label="每日推送" name="3"></el-tab-pane>
                    <el-tab-pane label="每周推送" name="4"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="table">
                <el-table :data="settingData" style="width: 100%;" height="100%" >
                    <el-table-column fixed prop="name" label="消息名称" width="150" align="center"></el-table-column>

                    <el-table-column label="推送时间" width="180" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.sned_time">{{scope.row.sned_time}}</div>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="create_time" label="创建时间" width="200" align="center"></el-table-column>
                    <el-table-column prop="template_name" label="使用模板" width="200" align="center"> </el-table-column>

                    <el-table-column label="推送对象" width="200" align="center">
                        <template slot-scope="scope">
                            <div class="push_fans_type" v-if="scope.row.push_fans_type == 1">选择：全部粉丝</div>
                            <div class="push_fans_type" v-if="scope.row.push_fans_type == 2">选择：粉丝标签</div>

                            <div style="color: #FF6056;" v-for="(item, index) in scope.row.exclude_fans_tags_names" :key="item.id">
                                <span v-if="index == 0">排除：</span>
                                <span>{{item.name}}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.push_status == 1">未开始</div>
                            <div style="color: #1A54EC" v-if="scope.row.push_status == 2">进行中</div>
                            <div style="color: rgb(103, 194, 58);" v-if="scope.row.push_status == 3">已推送</div>
                            <div style="color: #FF6056;" v-if="scope.row.push_status == 4">已取消</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="完成时间" width="180" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.finish_time">{{scope.row.finish_time}}</div>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="开关" width="100" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.push_switch_status"
                             active-color="#FF6056" @change="changeSwitch(scope.row.id, scope.row.push_switch_status)">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column prop="arrive_user_count" label="送达人数" width="150" align="center"></el-table-column>

                    <el-table-column fixed="right" label="操作" width="180" align="center">
                        <template slot-scope="scope">
                            <div class="btn-wrap">
                                <div class="btn del-btn" @click="del(scope.row.id, scope.$index)">删除</div>
                                <div class="btn" @click="resend(scope.row)">再次发送</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="paging-item" v-if="settingData.length > 0">
                <el-pagination background @size-change="handleSizeChange"
                 @current-change="handleCurrentChange" :current-page.sync="currentPage"
                 layout="total, prev, pager, next, jumper" :total="signalTotal">
                </el-pagination>
            </div>


        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    export default {
        data() {
            return {
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,
                messName: '',
                settingData: [],
                sendType: '1',
                page: 1,
                maxPage: 10,
                settingData: [],
                signalTotal: '',
                currentPage: 1

            }
        },
        mounted() {
            // 获取公众号的模板消息推送列表
            this.templatSettingList();

        },
        methods: {
            // 新增消息推送
            linkNewMess() {
                this.$router.push({
                    path: '/newMessage',
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },
            // 获取公众号的模板消息推送列表
            templatSettingList() {
                axios.get(global.wechatURL + "third/templat_setting_list", {
                    params: {
                        appid: this.appid,
                        send_type: this.sendType,
                        page: this.page,
                        max_page: this.maxPage,
                        name: this.messName
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var List = res.data.data.data;
                        List.forEach((item) => {
                            if(item.push_switch_status == 0) {
                                item.push_switch_status = false;
                            } else if(item.push_switch_status == 1) {
                                item.push_switch_status = true;
                            }
                            if(item.finish_time !== null) {
                                item.finish_time = this.timeFormat(item.finish_time);
                            }
                            if(item.sned_time !== null) {
                                item.sned_time = this.timeFormat(item.sned_time);
                            }
                            item.create_time = this.timeFormat(item.create_time);
                        })
                        this.settingData = List;
                        // console.log(this.settingData)
                        this.signalTotal = res.data.data.count;
                    } else {
                        this.$message({ message: res.data.message, type: 'error' })
                    }
                });
            },
            // 时间格式
            timeFormat(originVal) {
                const dt = new Date(originVal);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + '').padStart(2, '0');
                const d = (dt.getDate() + '').padStart(2, '0');
                const hh = (dt.getHours() + '').padStart(2, '0');
                const mm = (dt.getMinutes() + '').padStart(2, '0');
                const ss = (dt.getSeconds() + '').padStart(2, '0');
                return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
            },

            handleClick(event) {
                this.sendType = event.name;
                this.page = 1;
                this.currentPage = 1;
                this.settingData = [],
                this.templatSettingList();
            },
            // 修改状态
            changeSwitch(id, status) {
                var textType
                if(status == true) {
                    textType = 1;
                } else if(status == false) {
                    textType = 0;
                }
                axios.put(global.wechatURL + "third/templat_setting_list", {
                    // appid: this.appid,
                    id: id,
                    push_switch_status: textType
                }).then(res => {
                    if(res.data.status == 200) {
                        this.$message({ message: "修改成功!", type: 'success' })
                    } else {
                        this.$message({ message: res.data.message, type: 'error' })
                    }
                });
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                
            },
            handleCurrentChange(val) {
                this.page = val;
                this.templatSettingList();
            },
            // 删除
            del(id, index) {
                var that = this;
                that.$confirm('确定要删除此消息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios.delete(global.wechatURL + "third/templat_setting_list", {
                        params: {
                            // appid: that.appid,
                            id: id
                        }
                    }).then(res => {
                        if(res.data.status == 200) {
                            that.$message({
                                message: '删除成功!',
                                type: 'warning'
                            })
                            that.settingData.splice(index, 1);
                        } else {
                            that.$message({ showClose: true, message: res.data.message, type: 'error'})
                        }
                    });
                }).catch(() => {
                    console.log('取消删除');
                });
            },
            // 再次发送
            resend(item) {
                this.$router.push({
                    path: '/newMessage',
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg,
                        item: item
                    }
                });
            }


        }


    }
</script>


<style lang="less" scoped>
    .content {
        padding: 0 25px;
    }

    .title-item {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-left {
            display: flex;
            align-items: center;
            .title-t {
                margin-right: 35px;
                font-size: 18px;
                font-weight: bold;
                color: #252530;
            }
        }
        .title-right {
            display: flex;
            align-items: center;
            .rule-item {
                margin-right: 20px;
                /deep/.el-input-group__append, .el-input-group__prepend {
                    background-color: #FF6056;
                    color: #ffffff;
                    border:none;
                }
            }
            .btns {
                width: 100px;
                height: 38px;
                background: #FFFFFF;
                border: 1px solid #3E3F54;
                border-radius: 6px;
                font-size: 14px;
                color: #3E3F54;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .newReply-btn {
                margin-right: 20px;
            }
        }
    }
    
    .tab-pane {
        /deep/ .el-tabs__header {
            margin: 0;
        }
        /deep/ .el-tabs__nav-wrap::after {
            background-color: #FF6056; 
        }
        /deep/ .el-tabs__item.is-active {
            color: #fff;
            background: #FF6056;
        }
        /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
            padding-left: 20px;
        }
        /deep/ .el-tabs__active-bar {
            display: none;
        }
        /deep/ .el-tabs__item:hover {
            color: #fff;
            background: #FF6056;
        }
        /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
            padding-right: 20px;
        }
    }
    .table {
        padding-top: 1px;
        width: 100%;
        height: calc(100vh - 275px);
    }

    .btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            flex: 1;
            font-size: 14px;
            color: #1A54EC;
            cursor: pointer;
        }
        .del-btn {
            color: #FF6056;
        }
    }

    .paging-item {
        display: flex;
        justify-content: center;
    }

</style>